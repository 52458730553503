import { MainPage } from "@c/MainPage";
import { ConvertScript } from "@gazebo/nextjs/useScript";
import { HideInProd } from "@gazebo/release";
import type { NextPage } from "next";
import Link from "next/link";
import React from "react";

const GetStarted = () => {
  return (
    <Link href="/today">
      <a className="button is-medium is-primary" role="button" data-scroll="">
        <span>Write now</span>
      </a>
    </Link>
  );
};

const A = () => {
  return (
    <div className="p-4">
      <section className="hero is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column has-text-centered is-8">
                <h1 className="title is-1">A Digital Garden for your mind.</h1>
                <h2 className="subtitle my-3 is-5">
                  A calm space to log your work and dump your ideas.<br />Let your thoughts grow by themselves. Also share.
                </h2>
              </div>
            </div>
            <div className="columns my-2">
              <div className="column has-text-centered">
                <GetStarted />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="columns" id="benefits">
          <div className="column">
            <h2 className="title">
              Open.
            </h2>
            <p>
              All your notes are stored in markdown. You can download them and move on at any time.
              <br />
            </p>
          </div>
          <div className="column">
            <h2 className="title">
              Private.
            </h2>
            <p>
              All of your data is kept in your local storage for now. Later we&apos;ll use encryption
              and decentralized databases to provide you with total ownership of your space.
            </p>
          </div>
          <div className="column">
            <h2 className="title">
              In Progress…
            </h2>
            <p>
              This is the tip of the iceberg. We&apos;re building a space where (neuro)diverse people can find
              the one tool they crave. Or build it.
              <br />
              <em></em>
            </p>
          </div>
        </div>
        <HideInProd>
          <div className="columns my-5 is-vcentered is-centered">
            <div className="column has-text-centered is-8">
              <h2 className="title is-3"><em>We&apos;re building the space of a million tools<br />for a billion minds.</em></h2>
            </div>
          </div>
        </HideInProd>
        <div className="columns is-vcentered is-centered my-4">
          <div className="column is-8">
            <ConvertScript async data-uid="d31cced469" src="https://singulargarden.ck.page/d31cced469/index.js" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Home: NextPage = () => {
  return (
    <MainPage>
      <main className="container">
        <A />
      </main>
    </MainPage>
  );
};

export default Home;
