import { signout } from "@gazebo/firebase";
import { AuthSwitch, userUserId as useUserId } from "@gazebo/firebase/auth";
import { HideInProd } from "@gazebo/release";
import { useNotebookStore } from "@store";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";
import logo from "../../public/logo.png";
import { BulmaLink } from "./link";

const NEXT_PUBLIC_SITE_TITLE = process.env.NEXT_PUBLIC_SITE_TITLE;
const NEXT_PUBLIC_APP_VERSION = '0.1.0'

const PublicGarden = () => {
  const userId = useUserId()

  return <BulmaLink href={`/user/${userId}`}>
    <a className="navbar-item">Public Garden</a>
  </BulmaLink>
}

export const Header: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const router = useRouter();

  const doSignout = useCallback(async () => {
    await signout();
    router.replace("/");
  }, [router]);

  const toggle = useCallback(() => {
    setVisible((x) => !x);
  }, [setVisible]);

  const notebookStore = useNotebookStore()

  const [hoverSettings, setHoverSettings] = useState(false)

  return (
    <nav className="navbar is-light" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <AuthSwitch none loading anonymous>
            <Link href="/">
              <a className="navbar-item">
                <Image src={logo} alt={NEXT_PUBLIC_SITE_TITLE} />
                {/* <span>{NEXT_PUBLIC_SITE_TITLE}</span> */}
              </a>
            </Link>
          </AuthSwitch>
          <AuthSwitch logged>
            <Link href="/">
              <a className="navbar-item">
                <Image src={logo} alt={NEXT_PUBLIC_SITE_TITLE} />
                {/* <span>{NEXT_PUBLIC_SITE_TITLE}</span> */}
              </a>
            </Link>
          </AuthSwitch>
          <a
            role="button"
            className={`navbar-burger ${visible ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={toggle}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${visible ? "is-active" : ""}`}>
          <div className="navbar-start">
            <BulmaLink href="/">
              <a className="navbar-item">Home</a>
            </BulmaLink>
            <BulmaLink href="/today">
              <a className="navbar-item">Today</a>
            </BulmaLink>
            <BulmaLink href="/space">
              <a className="navbar-item">Space</a>
            </BulmaLink>
            <BulmaLink href="/emakimono">
              <a className="navbar-item">Emakimono</a>
            </BulmaLink>
            <HideInProd>
              <BulmaLink href="/graph">
                <a className="navbar-item">Graph</a>
              </BulmaLink>
            </HideInProd>
          </div>
          <div className="navbar-end">
            <AuthSwitch logged>
              <PublicGarden />
            </AuthSwitch>
            <HideInProd>
              <AuthSwitch logged>
                <BulmaLink href="/account">
                  <a className="navbar-item">Account</a>
                </BulmaLink>
              </AuthSwitch>
            </HideInProd>
            <div className={`navbar-item has-dropdown is-hoverable`}>
              <a className="navbar-link">
                <i className="fas fa-cog"></i>
              </a>
              <div className="navbar-dropdown is-right">
                <div className="navbar-item">
                  <AuthSwitch none loading>
                    <button className="button is-small is-primary is-light is-loading"
                      style={{ width: '100%' }}
                    >
                      Loading
                    </button>
                  </AuthSwitch>
                  <AuthSwitch anonymous>
                    <BulmaLink href="/signin">
                      <a className="button is-small is-primary"
                        style={{ width: '100%' }}>
                        <strong>Sign up</strong>
                      </a>
                    </BulmaLink>
                  </AuthSwitch>
                  <AuthSwitch logged>
                    <button
                      className="button is-small is-primary is-light"
                      style={{ width: '100%' }}
                      onClick={doSignout}
                    >
                      Signout
                    </button>
                  </AuthSwitch>
                </div>
                <div className="navbar-item">
                  <button className="navbar-item button is-primary is-small is-outlined has-tooltip-bottom has-tooltip-arrow"
                    onClick={() => notebookStore.asZip()}
                    style={{ width: '100%' }}
                    data-tooltip="Download a zip file with all your notes">
                    Download
                  </button>
                </div>
                <hr className="navbar-divider" />
                <div className="navbar-item">
                  Version {NEXT_PUBLIC_APP_VERSION}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav >
  );
};
